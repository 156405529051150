/**
 * TODO:
 * Due to time constraints, this file has not yet been refactored into a functional component.
 * It is recommended to refactor it to a functional component to align with current standards.
 * Additionally, the import method for global components should be updated to match
 * the approach used in the page creation process.
 */
import React, { Component } from 'react';
import StoryblokClient from 'storyblok-js-client';
import { SEO } from '../components/custom/seo';
import { FontOverride } from '../components/custom/font-override';
import { HelmetHead } from '../components/custom/head';
import { blokToComponent } from '../helpers/blok-to-component';
import { DomService } from '../services/dom';
import { EditorService } from '../services/editor';
import { LanguageService } from '../services/language';
import { StoryDataFromGraphQLQuery } from '../services/language/types';
import { StoryblokService } from '../services/storyblok';
import { getComponent } from '../helpers/get-component';
import { HeaderSection } from '../components/custom/header-section';
import { getAlternates } from '../services/dom/util';

type StoryblokEntryProps = Record<string, unknown>;
type StoryblokEntryState = any;

const RocheGlobalConfig = getComponent('roche-global-config') as React.ElementType;
const Breadcrumbs = getComponent('roche-breadcrumbs') as React.ElementType;

// eslint-disable-next-line import/no-default-export
export default class StoryblokEntry extends Component<StoryblokEntryProps, StoryblokEntryState> {
  private storyblokClient: StoryblokClient;

  private storyblokBridge: any;

  private url: URL;

  public constructor(props: StoryblokEntryProps) {
    super(props);
    this.handleLogin = this.handleLogin.bind(this);
    this.handleStoryblokLoad = this.handleStoryblokLoad.bind(this);
    this.loadStory = this.loadStory.bind(this);
    this.setState = this.setState.bind(this);

    this.state = {} as any;
  }

  /*
   * While editing, mismatches between the "real" DOM and React's virtual DOM might happen.
   * When errors like these occur React DOM unmounts, resulting in a blank page.
   *
   * Slider children, are especially prone to this exception upon deletion.
   * This is caused by the 'input' event handling.
   * To minimise editor frustration we handle these using error boundaries.
   *
   * More info:
   * https://reactjs.org/docs/reconciliation.html
   * https://reactjs.org/docs/error-boundaries.html
   */
  static getDerivedStateFromError(): { hasError: true } {
    return { hasError: true };
  }

  public async componentDidMount(): Promise<void> {
    this.storyblokClient = new StoryblokClient({
      accessToken: process.env.GATSBY_STORYBLOK_SPACE_API_KEY,
    });

    EditorService.loadStoryblokBridge(this.handleStoryblokLoad);
    window.addEventListener('rocheLoginComplete', this.handleLogin);
  }

  public render(): JSX.Element {
    if (this.state.hasError) {
      return (
        <div>
          <h1>Something went wrong!</h1>
          <p>Your work is not lost, but you will not see any new changes until you save.</p>
          <button onClick={(): void => window.location.reload()}>Refresh editor view and hide this error.</button>
        </div>
      );
    }

    const { breadcrumbs, emergencyBanner, footer, home, navigation, onClickNotice, story } = this.state;
    if (!story?.content) {
      return <div></div>;
    }
    const canShowFooter = !story.content.hide_footer;
    const canShowStockPrice = !story.content.hide_stock_price;
    const isStoryTypeContent = story.content.component === 'story';
    const canShowBreadcrumbs = !story.content.hide_breadcrumbs && !isStoryTypeContent;
    const displayMicrositeHeader = story.content.microsite_header;

    return (
      <div>
        <FontOverride overrides={this.state.fontOverwrites} />
        <SEO lang={story.lang}></SEO>
        <HelmetHead title={story.content.teaser_title} lang={story.lang} />
        <RocheGlobalConfig
          {...DomService.getGlobalConfig(story)}
          tagPageUrl={`${window.location.origin}/${this.state.tagPageUrl}`}
        />
        <roche-animation-manager />

        <HeaderSection
          home={home}
          storyContent={story?.content}
          navigationContent={navigation?.content}
          alternates={getAlternates(story.alternates)}
        />

        {canShowBreadcrumbs && !displayMicrositeHeader && <Breadcrumbs list={breadcrumbs || []} />}
        {blokToComponent({ blok: story.content, getComponent, storyId: story.id })}
        {canShowFooter && footer && blokToComponent({ blok: { ...footer?.content, canShowStockPrice }, getComponent })}
        {onClickNotice && blokToComponent({ blok: onClickNotice.content, getComponent })}
        {emergencyBanner && blokToComponent({ blok: emergencyBanner.content, getComponent })}
      </div>
    );
  }

  private async handleStoryblokLoad(): Promise<void> {
    this.loadStory();

    this.storyblokBridge = StoryblokService.getObject();
    if (!this.storyblokBridge) {
      return;
    }

    this.storyblokBridge.on(['change', 'published'], () => window.location.reload());
    this.storyblokBridge.on('input', ({ story }) => {
      const activeLanguage = LanguageService.getActiveLanguage();
      const updatedStory = { ...story, lang: activeLanguage };
      const globalConfig = DomService.getGlobalConfig(updatedStory);

      this.setState({ story: updatedStory, ...globalConfig });
    });

    this.storyblokBridge.pingEditor(() =>
      this.storyblokBridge.isInEditor() ? this.storyblokBridge.enterEditmode() : DomService.activateConsentScript(),
    );
  }

  private getStoryIdentifier(): string {
    // ID, which is the prefered method by storyblok
    const storyId = this.url.searchParams.get('_storyblok');
    // path url param, as fallback
    const path = this.url.searchParams.get('path');

    return storyId || path;
  }

  private async loadStory(): Promise<void> {
    this.url = new URL(window.location.href);

    const queryParams = {
      version: 'draft',
      resolve_relations: '',
      resolve_links: 'url',
    } as Record<string, unknown>;

    const { data } = await this.storyblokClient.getStory(this.getStoryIdentifier(), queryParams);
    const lang = LanguageService.getActiveLanguage();

    const story = {
      ...data.story,
      // inject lang from path, just like during create page
      lang,
      // inject allSpaceLocales, just like during create page
      allSpaceLocales: [lang],
    } as StoryDataFromGraphQLQuery;

    this.setState({
      story,
      ...DomService.getGlobalConfig(story as any),
    });

    await EditorService.loadGlobalComponents(this.storyblokClient, (...args) =>
      this.setState(...args, () => {
        const {
          content: {
            emergency_banner: emergencyBannerUuid,
            footer: footerUuid,
            home: homeUuid,
            navigation: navigationUuid,
            on_click_notice: onClickNoticeUuid,
            search: searchUuid,
          },
        } = this.state.globalComponents;

        EditorService.loadDefaultLanguageHome(
          homeUuid,
          'defaultLanguageHome',
          this.storyblokClient,
          this.setState,
          queryParams,
        );

        [
          [emergencyBannerUuid, 'emergencyBanner'],
          [footerUuid, 'footer'],
          [homeUuid, 'home'],
          [navigationUuid, 'navigation'],
          [onClickNoticeUuid, 'onClickNotice'],
          [searchUuid, 'search'],
        ].forEach(([uuid, stateKeyName]) =>
          EditorService.loadGlobalComponent(uuid, stateKeyName, this.storyblokClient, this.setState, queryParams, lang),
        );
      }),
    );
    EditorService.loadBreadcrumbs(story.full_slug, this.setState);
    const fontOverwrites = await EditorService.loadFontOverwrites(this.storyblokClient);

    this.setState({
      ...this.state,
      fontOverwrites,
    });
  }

  private handleLogin(): void {
    StoryblokService.redirect(({ data: { story } }) => {
      this.setState({
        story,
        ...DomService.getGlobalConfig(story as any),
      });
    });
  }
}
